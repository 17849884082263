import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
   enterPriseReportData: [],
   statusEnterPrise: "",
   isErrorEnterPrise: null,
   isLoadingEnterPrise: false,
};

// https://api.dentalbookingonline.com/api/Practice/Get-EnterPriseReports?startDate=2024-11-01&endDate=2024-11-28
export const fetchEnterPriseReport = createAsyncThunk(
   "enterPrise/enterPriseReport",
   async (params, { rejectWithValue }) => {
      // console.log('params', params)
      try {
         const { data } = await axiosInstance.get(
            `/api/Practice/Get-EnterPriseReports?startdate=${params.startDate}&enddate=${params.endDate}`
         );
         // console.log("data",data);
         return data;
      } catch (error) {
         return rejectWithValue("data Not Found");
      }
   }
);

const enterPriseReportSlice = createSlice({
   name: "enterPriseReport",
   initialState,
   extraReducers: (builder) => {
      builder
         .addCase(fetchEnterPriseReport.fulfilled, (state, { payload }) => {
            // console.log("payload",payload);
            if (payload.status.isSuccess === true){
               state.enterPriseReportData = payload.data;
            }else{
               state.enterPriseReportData = [];
            }
            state.isLoadingEnterPrise = false;
            state.statusEnterPrise = "Success";
            state.isErrorEnterPrise = false;
         })
         .addCase(fetchEnterPriseReport.pending, (state) => {
            state.enterPriseReportData = [];
            state.isLoadingEnterPrise = true;
            state.statusEnterPrise = "";
            state.isErrorEnterPrise = false;
         })
         .addCase(fetchEnterPriseReport.rejected, (state) => {
            state.enterPriseReportData = [];
            state.isLoadingEnterPrise = false;
            state.isErrorEnterPrise = true;
            state.statusEnterPrise = "Rejected";
         })
        
   },
});

export default enterPriseReportSlice.reducer;
