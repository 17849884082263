import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../helper/axiosInstance/axiosInstance';

const initialState = {
  tccCollectionReportsData: [],
  isTccReportDataLoading: false,
  isError: null,
  status: '',

  
};



export const fetchTccCollectionReportData = createAsyncThunk(
  'totalTccCollectionReportData/totalTccCollectionReportDataList',
  async (params, { rejectWithValue }) => {
    // console.log("params",params);
    try {
      const { data } = await axiosInstance.get(
        // https://api.dentalbookingonline.com/api/Dashboard/tcccollectionreports/{startDate}/{endDate}
        `api/Dashboard/tcccollectionreports/${params.startDate}/${params.endDate}`
      );
      // console.log("data",data);
      return data;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

const EmployeeProdValueSlice = createSlice({
  name: 'totalTccCollectionReportDataList',
  initialState,
  extraReducers: {
   
    [fetchTccCollectionReportData.fulfilled]: (state, { payload }) => {
        // console.log("payload",payload);
      if (payload.status.isSuccess === true) {
        state.tccCollectionReportsData = payload.data;
      } else {
        state.tccCollectionReportsData = [];
      }
      state.isTccReportDataLoading = false;
      state.status = 'Success';
      state.isError = null;
    },
    [fetchTccCollectionReportData.pending]: (state, { payload }) => {
      state.isTccReportDataLoading = true;
      state.status = '';
      state.isError = null;
      state.tccCollectionReportsData = [];
    },
    [fetchTccCollectionReportData.rejected]: (state, { payload }) => {
      state.isTccReportDataLoading = false;
      state.isError = payload;
      state.status = 'Rejected';
      state.tccCollectionReportsData = [];
    },
  },
});

export default EmployeeProdValueSlice.reducer;
